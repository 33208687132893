import React from 'react';
import Image from 'next/image';

interface Step {
  id: number;
  stepNumber: string;
  description: string;
  imageUrl: string;
  orientation: 'right' | 'left'; // To alternate layout
}

const stepsData: Step[] = [
  {
    id: 1,
    stepNumber: '1',
    description: 'Seleziona i tuoi broker',
    imageUrl: '/images/step/1.png',
    orientation: 'right',
  },
  {
    id: 2,
    stepNumber: '2',
    description: 'Importa le tue transazioni',
    imageUrl: '/images/step/2.png',
    orientation: 'left',
  },
  {
    id: 3,
    stepNumber: '3',
    description: 'Conferma che i tuoi dati siano corretti',
    imageUrl: '/images/step/3.png',
    orientation: 'left',
  },
  {
    id: 4,
    stepNumber: '4',
    description: 'Scarica i file',
    imageUrl: '/images/step/4.png',
    orientation: 'right',
  },
];

const Steps: React.FC = () => {
  return (
    <section className="relative md:w-2/3 xl:w-1/2 py-10 mx-auto mt-16">
      {/* Background Image */}
      <div className="absolute mt-[445px] right-[100px] -z-10 sm:block hidden items-center flex justify-center">
        <Image
          src='/images/circle-sections.svg'
          width={600} // Adjusted width
          height={0}
          alt='shape in the background'
          className="sm:w-[360px] h-auto"
        />
      </div>

      <h2 className="text-center text-2xl font-bold text-[#00008b] mb-16">Come funziona?</h2>
      <div className="flex flex-col space-y-12 relative z-10">
        {stepsData.map((step) => (
          <div
            key={step.id}
            className={`flex flex-col sm:flex-row ${
              step.orientation === 'left' ? 'sm:flex-row-reverse' : ''
            } items-center justify-between space-y-6 sm:space-y-0`}
          >
            {/* Step Text */}
            <div className={`w-full sm:w-1/2 flex flex-col items-center text-center px-4 ${step.id === 2 ? "sm:mt-[-100px]" : step.id === 3 ? "sm:mb-[-120px]" : ""}`}>
              <div className="step-number text-5xl font-bold text-secondary">{step.stepNumber}</div>
              <p className="text-xl font-semibold mt-4">{step.description}</p>
            </div>
            {/* Step Image */}
            <div className="mx-auto w-[200px] sm:w-[250px] flex justify-center">
              <Image
                src={step.imageUrl}
                width={225}
                height={468}
                alt={`Step ${step.stepNumber}`}
                className={`w-full h-auto ${step.id === 2 ? "sm:mt-[-100px]" : step.id === 3 ? "sm:mb-[-100px]" : ""}`}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Steps;
