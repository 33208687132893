import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt, faDollarSign, faBook, faStar, faUserPlus, faSignIn } from '@fortawesome/free-solid-svg-icons';

import { getToken, removeToken, makeAuthenticatedRequest } from '../utils/authToken';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Button from './Button';

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const router = useRouter();

  // Helper to check login status based on the token
  const checkLoginStatus = () => {
    return !!getToken();
  };
  
  const handleLogout = useCallback(() => {
    removeToken();
    setIsLoggedIn(false); // Set isLoggedIn to false immediately after logging out
    if (router.pathname === '/') {
      router.reload();
    }
  }, [router]);

  const checkAuth = useCallback(async () => {
    if (!checkLoginStatus()) {
      setIsLoggedIn(false); // If there's no token, the user is not logged in
      return;
    }
    try {
      const response = await makeAuthenticatedRequest(`${process.env.NEXT_PUBLIC_BACK_END_URL}/api/v1/auth/name`, {});

      if (!response.ok) {
        throw new Error('Token invalid or expired');
      }
      const data = await response.json();
      setUsername(data.username);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Token expired or invalid:', error);

      // Log out if the token is expired
      setIsLoggedIn(false); // If the request fails, we assume the token is expired or invalid
      removeToken();
      router.reload();
    }
  }, []);

  // Periodically check if the user is still logged in
  useEffect(() => {
    // Check login status when the component mounts
    checkAuth();

    // Periodically check login status every 5 minutes (300000 ms)
    const intervalId = setInterval(checkAuth, 300000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [handleLogout, router]);

  const isAuthPage = 
    router.pathname === '/login' 
    || router.pathname === '/register'
    || router.pathname === '/password-reset-request'
    || router.pathname === '/reset-password';

  return (
    <nav className="bg-transparent w-screen relative h-0 z-50 md:top-5">
      <div className="container w-full mx-auto flex items-center justify-between navbar px-4 px-0">
        <div className="hover:rotate-[380deg] transition-transform duration-500">
          <Link href="/">
            <Image src={isAuthPage ? '/images/loghi/logo.png' : '/images/logo.svg'} alt="Geraldone" width={70} height={70} className="w-50 h-50 md:w-70 md:h-70" />
          </Link>
        </div>
        {!isAuthPage && (
          <div className="flex items-center space-x-4">
            <ul className="hidden md:flex space-x-4 items-center">
              <li>
                <Link href="#about" className="text-white link-animation mr-6">
                  Chi è?
                </Link>
              </li>
              {/*
              <li>
                <Link href="/blog" className="text-white link-animation mr-6">
                  Blog
                </Link>
              </li>
              <li>
                <Link href="/pricing" className="text-white link-animation mr-6">
                  Prezzi
                </Link>
              </li>  
              */}
              {isLoggedIn ? (
                <>
                  <li>
                    <Link href="/dashboard" className="bg-secondary px-5 py-3 rounded-full font-bold hover:bg-white text-primary hover:border-primary text-xl transition-colors mr-[-10px]">
                      Al tuo servizio
                    </Link>
                  </li>
                  <li>
                    <Button onClick={handleLogout} variant='red-icon' className='p-3 flex items-center justify-center'>
                      <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="w-6 h-6"/>
                    </Button>
                  </li>
                </>
              ) : (
                <>
                  {router.pathname === '/' && (
                    <li>
                      <Link href="/register" className="bg-transparent border-4 border-solid border-blue-400 text-white px-5 py-3 rounded-full hover:bg-white hover:text-secondary hover:border-orange-400 text-xl transition-colors ">
                        Registrati
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link href="/login" className={`bg-secondary font-bold text-dark-blue px-5 py-2 rounded-full text-base transition-colors mr-4 ${router.pathname === '/' ? 'text-xl px-5 py-3 border-4 border-solid border-transparent hover:border-orange-400 hover:bg-white hover:text-primary' : 'hover:bg-primary hover:text-white'}`}>
                      Log In
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <button className="focus:outline-none md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
              <span className="text-white hover:text-orange transition-colors duration-200">
                <FontAwesomeIcon icon={faBars} size="2x" className="text-white w-14 h-14 mt-[-14px]" />
              </span>
            </button>
          </div>
        )}
      </div>
      {!isAuthPage && menuOpen && (
        <div className="fixed inset-0 bg-primary z-50 flex flex-col items-start p-4">
          <div className="flex justify-between w-full mb-4">
            <span className="text-white text-lg my-auto ml-5 font-bold">{isLoggedIn ? `Ciao ${username || 'Carissimo'}!` : ''}</span>
            <button onClick={() => setMenuOpen(false)}>
              <FontAwesomeIcon icon={faBars} size="2x" className="text-white w-14 h-14" />
            </button>
          </div>
          <ul className="flex flex-col items-start space-y-4 w-full">
            {isLoggedIn ? (
              <li className="w-full">
                <Link href="/dashboard" className="flex items-center px-4 py-2 text-white hover:text-white w-full">
                  <FontAwesomeIcon icon={faStar} className="mr-2 w-6 h-6" />
                  <span>Al tuo servizio</span>
                </Link>
              </li>
            ) : (
              <>
                <li className="w-full">
                  <Link href="/login" className="flex items-center px-4 py-2 text-white hover:text-white w-full">
                    <FontAwesomeIcon icon={faSignIn} className="mr-2 w-6 h-6" />
                    <span>Log In</span>
                  </Link>
                </li>
                <li className="w-full">
                  <Link href="/register" className="flex items-center px-4 py-2 text-white hover:text-white w-full">
                    <FontAwesomeIcon icon={faUserPlus} className="mr-2 w-6 h-6" />
                    <span>Registrati</span>
                  </Link>
                </li>
              </>
            )}
            <li className="w-full">
              <Link href="#about" className="flex items-center px-4 py-2 text-white hover:text-white w-full">
                <Image src="/images/logo_bw.svg" alt="Features" width={24} height={24} className="mr-2" />
                <span>Chi è Geraldone?</span>
              </Link>
            </li>
            {/*
            <li className="w-full">
              <Link href="/about" className="flex items-center px-4 py-2 text-white hover:text-white w-full">
                <FontAwesomeIcon icon={faBook} className="mr-2 w-6 h-6" />
                <span>Blog</span>
              </Link>
            </li>
            <li className="w-full">
              <Link href="/pricing" className="flex items-center px-4 py-2 text-white hover:text-white w-full">
                <FontAwesomeIcon icon={faDollarSign} className="mr-2 w-6 h-6" />
                <span>Prezzi</span>
              </Link>
            </li>
            */}
            {isLoggedIn && (
              <>
                <li className="w-full">
                  <button onClick={handleLogout} className="flex items-center px-4 py-2 text-white hover:text-white w-full text-left">
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 w-6 h-6" />
                    <span>Log Out</span>
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
