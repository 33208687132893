import React from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import TermsAndConditionsPopup from './Terms';
import PrivacyPolicyPopup from './PrivacyPolicy';
import Link from 'next/link';

const Footer: React.FC = () => {
  const router = useRouter();
  const [isTermsOpen, setIsTermsOpen] = React.useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = React.useState(false);

  // Check if the current page is the homepage
  const isHomePage = router.pathname === '/';

  // Full footer for the homepage
  if (isHomePage) {
    return (
      <>
        <footer className="bg-black text-white p-8 relative bottom-0 text-center z-30">
          <div className="flex flex-col items-center">
            {/* Logo */}
            <Image
              src={`/images/loghi/logo_white.png`}
              alt="Logo"
              width={50}
              height={50}
              className="mb-8 mt-4 h-auto w-auto"
            />
            {/* Links */}
            <ul className="flex flex-col space-y-4 mb-4 text-center">
              <li>
                <button
                  className="text-white hover:text-orange-500"
                  onClick={() => setIsTermsOpen(true)}
                >
                  Termini e Condizioni
                </button>
              </li>
              <li>
                <button
                  className="text-white hover:text-orange-500"
                  onClick={() => setIsPrivacyOpen(true)}
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <span onClick={() => router.push('/contact')}
                  className="text-white hover:text-orange-500 cursor-pointer"
                >
                  Contattaci
                </span>
              </li>
            </ul>

            {/* Social Media Icons */}
            <div className="flex justify-center mt-4 mb-8">
              <Link href="https://instagram.com/geraldone_app" target="_blank" rel="noopener noreferrer">
                <Image
                  src="/images/icons_ig.svg"
                  alt="Profilo Instagram"
                  width={40}  // Set the width you want
                  height={40}  // Set height to 0
                  className='mx-[15px] hover:animate-spin w-[40px] h-auto'
                />
              </Link>
              <Link href="https://www.youtube.com/channel/UC4ngG3nKS6wU0FZIVCI44mA" target="_blank" rel="noopener noreferrer">
                <Image
                  src="/images/icons_yt.svg"
                  alt="Canale YouTube"
                  width={40}  // Set the width you want
                  height={36}  // Set height to 0
                  className='mx-[15px] hover:animate-spin w-[40px] h-auto'
                />
              </Link>
              <Link href="https://www.tiktok.com/@geraldone_app" target="_blank" rel="noopener noreferrer">
                <Image
                  src="/images/icons_tt.svg"
                  alt="Canale TikTok"
                  width={35}  // Set the width you want
                  height={35}  // Set height to 0
                  className='mx-[15px] hover:animate-spin w-[35px] h-auto'
                />
              </Link>
            </div>
            <div>
              {/*<p className="text-sm mt-2">Business ID #811975088</p>
              <p className="text-sm ">Shaban Polluzha, 15, 10000, Pristina, XK</p>
              <p className="text-sm ">Email: {process.env.NEXT_PUBLIC_SUPPORT_EMAIL}</p>
              <p className="text-sm mb-2">Tel.: +383 (0)49 657 536</p>*/}
              <p className="text-sm mt-6 sm:inline block">
                <span className="sm:inline block">&copy; {new Date().getFullYear()} | Italian Coffee & Crafts LLC</span>
                <span className="hidden sm:inline"> - </span>Tutti i diritti riservati
              </p>
            </div>
          </div>
        </footer>

        {/* Popups */}
        <TermsAndConditionsPopup isOpen={isTermsOpen} closePopup={() => setIsTermsOpen(false)} />
        <PrivacyPolicyPopup isOpen={isPrivacyOpen} closePopup={() => setIsPrivacyOpen(false)} />
      </>
    );
  } else {
    // Compact footer for other pages
    return (
      <footer
        style={{
          backgroundColor: '#00003b',
          color: 'white',
          padding: '1rem',
          height: '4rem',
          position: 'relative',
          bottom: 0,
          textAlign: 'center',
          width: 'full',
          zIndex: 30,
        }}
      >
        <p className="text-xs sm:mt-4 sm:inline block">
          <span className="sm:inline block">&copy; {new Date().getFullYear()} | Italian Coffee & Crafts LLC</span>
          <span className="hidden sm:inline"> - </span>Tutti i diritti riservati
        </p>
      </footer>
    );
  }
};

export default Footer;
